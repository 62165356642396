<template>
    <div class="course-ware-content" :native="false">
        <wareDetailModule :typeId="typeId" :zhangIndex="zhangIndex" :jieIndex="jieIndex" :file_path="file_path"
                          :resource_id="resource_id"/>
    </div>
</template>

<script>
    import wareDetailModule from './../../components/teacherCourse/wareDetailModule.vue'

    export default {
        name: "teacherCourseWareDetail",
        props: ['courseInfos', 'nodeIndex', 'chapterIndex'],
        components: {
            wareDetailModule
        },
        data() {
            return {
                typeId: '',
                zhangIndex: '',
                jieIndex: '',
                file_path: '',
                resource_id: '',
            }
        },
        created() {
            // this.getCourseInfo();
            this.typeId = this.$route.query.typeId;
            this.zhangIndex = this.$route.query.zhangIndex;
            this.jieIndex = this.$route.query.jieIndex;
            this.file_path = this.$route.query.file_path;
            this.resource_id = this.$route.query.id;
        },
        methods: {
            handleClick(val) {
                this.$emit('handleSave', true)
            },
            handleList(val) {
                this.$emit('handleList', val)
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-breadcrumb {
        padding-bottom: 20px;
    }

    .course-ware-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
</style>