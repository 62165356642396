import { render, staticRenderFns } from "./teacherCourseWareDetail.vue?vue&type=template&id=4f4da07c&scoped=true&"
import script from "./teacherCourseWareDetail.vue?vue&type=script&lang=js&"
export * from "./teacherCourseWareDetail.vue?vue&type=script&lang=js&"
import style0 from "./teacherCourseWareDetail.vue?vue&type=style&index=0&id=4f4da07c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f4da07c",
  null
  
)

export default component.exports